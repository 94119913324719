import Head from "next/head";
import Image from "next/image";

const Custom404 = () => {
  return (
    <>
      <Head>
        <title>Page not found - Aurum Lab</title>
      </Head>
      <main>
        <div className="center">
          <div
            style={{
              // marginTop: "5rem",
              marginBottom: "5rem",
              textAlign: "center",
            }}
          >
            <Image
              style={{
                width: "40%",
                height: "auto",
              }}
              src="/img/404_1.webp"
              priority
              width={500}
              height={500}
              alt="404"
            />
            <h3>Oops! That page can&apos;t be found.</h3>
            <p>The page you are looking for does not exist.</p>
          </div>
        </div>
      </main>
    </>
  );
};
export default Custom404;
